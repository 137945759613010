
@if(user && ( (user.role === 'partner') || (user.role === 'admin') ) ) {
  <ng-container *transloco="let t">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark _navbar-inverse _mt-40 _mb-40">
        <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-controls="navbarNav" [attr.aria-expanded]="!isNavbarCollapsed" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div [ngbCollapse]="isNavbarCollapsed" class="navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto">
            <!-- Mailing dropdown for admin role -->
              @if(user && user.role === 'admin') {
                <!-- Mail Templates -->
              <li class="nav-item">
                <a class="nav-link" routerLink="/mailing/templates">{{ t('navigation.mailTemplates') }}</a>
              </li>
              <!-- Mail Logs -->
              <li class="nav-item">
                <a class="nav-link" routerLink="/mailing/logs">{{ t('navigation.mailLogs') }}</a>
              </li>
              }
              @if(user && user.role === 'partner') {
              <!-- Partner profile -->
              <li class="nav-item">
                <a class="nav-link" routerLink="/partner/profile">{{ t('navigation.partnerProfile') }}</a>
              </li>
              <!-- Partner services -->
              <li class="nav-item">
                <a class="nav-link" routerLink="/partner/services">{{ t('navigation.partnerServices') }}</a>
              </li>
              }
          </ul>
        </div>
      </nav>
    </div>
  </ng-container>
}
